/* Layout. */

.page-container {
    min-height: 90vh;
}
.content-container {
    @include flexbox;
    @include flex-flow(row wrap);
    @include justify-content(space-between);
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 100vw;
    position: relative;
    width: 100vw;
    @include breakpoint($bp-desktop-narrow) {
        margin: 0 auto;
        padding: $bp-desktop-narrow-gutter;
    }
    @include breakpoint($bp-desktop-xwide) {
        max-width: $bp-desktop-xwide;
        width: $bp-desktop-xwide;
    }
    .container {
        max-width: 100vw;
        width: 100vw;
        @include breakpoint($bp-desktop-narrow) {
            display: block;
            max-width: 100%;
        }
        @include breakpoint($bp-desktop-wide) {
            width: $bp-desktop-wide;
        }
    }
}
