/* Headings. */

.sport-event {
    text-align: center;
    width: 33vw;
    &__details {
        color: $color-med-gray;
        font-family: $font-family-cond;
        font-size: 1.2rem;
        letter-spacing: 0.2rem;
        span {
            color: $color-link;
            text-transform: uppercase;
        }
    }
    &__title {
        color: $color-body-inverse;
        font-size: 1.4rem;
        font-weight: $weight-semibold;
        letter-spacing: 0.02rem;
        padding-top: 3px;
    }
    .main-nav & {
        display: none;
        @include breakpoint($bp-desktop-narrow) {
            display: block;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100vw;
        }
    }
    .block-video-player & {
        padding: $spacing-base8;
        width: 100vw;
        @include breakpoint($bp-desktop-narrow) {
            display: none;
        }
    }
}
