$button-social-standard: 48;

.button-social {
    border-radius: 5px;
    display: inline-block;
    font-size: 16px;
    height: $button-social-standard + 0px;
    line-height: $button-social-standard + 0px;
    overflow: hidden;
    width: $button-social-standard + 0px;

    &:before {
        display: block;
        text-align: center;
        width: 100%;
    }
    &:hover {
        cursor: pointer;
    }
    &:last-child {
        margin-right: 0;
    }
    &.round {
        border-radius: 50%;
    }
    &.rectangle {
        border-radius: 3px;
        cursor: pointer;
        display: block;
        font-size: 12px;
        height: $spacing-base10 * 2;
        line-height: 1.83;
        margin: 0;
        margin-right: 4px;
        padding: 0;
        position: relative;
        width: 30px;
    }
    
    a {
        transition: background-color $transition-speed + 0s ease;
    }
    .share-wrapper & {
        margin-right: 10px;
        @include breakpoint($bp-tablet) {
            display: block;
            float: none;
            margin-bottom: 10px;
            margin-right: 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .social-icons-bar & {
        display: inline-block;
        vertical-align: middle;
    }
    /* Button types */
    [class*='icon-moon-'] {
        color: $color-body-inverse;
        display: block;
        height: inherit;
        line-height: inherit;
        text-align: center;
        width: inherit;
        &:hover {
            color: $color-body-inverse;
        }
    }
    .icon-moon-facebook {
        background-color: $color-social-fb;
        &:hover {
            background-color: darken($color-social-fb, 10%);
        }
    }
    .icon-moon-twitter {
        background-color: $color-social-twitter;
        &:hover {
            background-color: darken($color-social-twitter, 10%);
        }
    }
    .icon-moon-instagram {
        background-color: $color-social-instagram;
        &:hover {
            background-color: darken($color-social-instagram, 10%);
        }
    }
    /* Button modifiers */
    .grayscale-icon {
        background-color: $color-off-black;
    }
}