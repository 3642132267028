/*
 * Base styles.
 */
@charset 'UTF-8';

/*
 * Potentially extract out printable css from definitions.
 */
 
/* 01. Core. */
@import 'core/mixins';
@import 'core/reset';
@import 'core/variables';
@import 'theme/theme';
@import 'core/animations';
@import 'core/breakpoints';
@import 'core/image-paths';
@import 'core/iconmoon';
@import 'core/functions';
@import 'core/typography';
@import 'core/base';

/* 02. Lib. */
@import 'lib/flex';
@import 'lib/jquery';

/* 03. Patterns. */
@import 'patterns/buttons';
@import 'patterns/headings';
@import 'patterns/images';
@import 'patterns/layout';
@import 'patterns/social';
