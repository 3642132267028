/* Buttons. */

@mixin button-bg($bg) {
    background-color: $bg;
    &:hover {
        background-color: darken($bg, 10%);
    }
}
@mixin button($bgColor, $borderColor: transparent, $icon: false, $dropdown: false ) { 
    @include button-bg($bgColor);
    border: 1px solid;
    border-color: $borderColor;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    letter-spacing: 0.2rem;
    line-height: 1;
    outline: none;
    padding: 0.55rem 0.5rem 0.5rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all $transition-speed + 0s ease-in-out;
    @if ($icon == true) {
        padding-left: 0;
    }
    @if ($borderColor == transparent) {
        padding-left: 0;
        padding-right: 0;
    }
    @if ($dropdown == true) {
        padding-right: 40px;
    }
}

@mixin button-load($bgColor: rgba($color-black, .25), $fullSchedule: false) {
    @include button-bg($bgColor);
    border: 1px solid transparent;
    color: $color-med-gray;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color $transition-speed + 0s ease-in-out;
    @if ($fullSchedule == false) {
        color: $color-body-inverse;
        font-size: 1.4rem;
        font-weight: $weight-bold;
        letter-spacing: 0.02rem;
        padding: $spacing-base5 $spacing-base16;
        &:after {
            @include icon-moon;
            content: $icon-moon-arrow;
            display: inline-block;
            font-size: 13px; // Keep 'px' so zooming in and out won't cause issues.
            margin-top: -3px;
            padding-left: $spacing-base5;
        }
    }
    @if ($fullSchedule == true) {
        font-size: 1.8rem;
        font-weight: $weight-semibold;
        letter-spacing: 0.2rem;
        padding: $spacing-base8 $spacing-base10 * 2.5;
        &:after {
            content: '';
        }
    }
    &.loading-ellipsis {
        background-color: transparent;
        border: 0;
        padding: 0;
    }
}

@mixin button-live($bgColor: $color-red) {
    background-color: $bgColor;
    border: 1px solid transparent;
    border-radius: 2px;
    color: $color-body-inverse;
    font-size: 1.2rem;
    font-weight: $weight-semibold;
    letter-spacing: 0.2rem;
    padding: $spacing-base5 $spacing-base10 * 2.2;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}