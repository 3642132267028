/* Base Core. */

html {
    background-color: $color-bg;
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $weight-regular;
    line-height: 1.15;

    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

    //@include breakpoint($bp-tablet) { // width < 900?
        //font-size: 50%; //1 rem = 8px, 8/16 = 50%
    //}
    //@include breakpoint($bp-desktop-wide) { // width < 1200?
        //font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
    //}
    //@include breakpoint($bp-desktop-wide) {
        //font-size: 75%; //1rem = 12, 12/16
    //}
}
body {
    box-sizing: border-box;
    color: $color-body;
    padding: 0;
}
::selection {
    background-color: $color-primary;
    color: $color-white;
}
ol,
li {
	list-style: none;
}
a {
    transition: color $transition-speed + 0s ease;
    &:active,
    &:focus {
        outline: none;
    }
}
img {
    display: block;
    height: auto;
    max-width: 100%;
    opacity: 1;
    transition: opacity $transition-speed2 + 0s ease-in $transition-speed2 + 0s;
    &.is-lazy-image {
        display: block;
        line-height: 0;
        opacity: 0;
        transition: opacity $transition-speed2 + 0s ease-in $transition-speed2 + 0s;
    }
}
.full-width {
    max-width: 100vw;
    width: 100%;
}
.hide {
    display: none !important;
}
.disclaimer-text {
    color: rgba($color-white, .5);
    display: block;
    font-size: .85rem;
    letter-spacing: .1rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
}