/* Icon moon. */

/* Variables. */
$font-family-icons: 'icon-moon';
$icon-moon-cache: 'g8cawz'; // Change this with every update.

$icon-moon-close: "\e90f";
$icon-moon-info: "\e910";
$icon-moon-arrow: "\e90d";
$icon-moon-facebook: "\e90e";
$icon-moon-location: "\e900";
$icon-moon-arrow-down: "\e901";
$icon-moon-arrow-up: "\e902";
$icon-moon-filters: "\e903";
$icon-moon-free: "\e904";
$icon-moon-help: "\e905";
$icon-moon-listen: "\e906";
$icon-moon-my-account: "\e907";
$icon-moon-nav-bars: "\e908";
$icon-moon-subscribe: "\e909";
$icon-moon-sync: "\e90a";
$icon-moon-twitter: "\e90b";
$icon-moon-watch: "\e90c";


$icon-moon-list:
    'icon-moon-close' $icon-moon-close,
    'icon-moon-info' $icon-moon-info,
    'icon-moon-location' $icon-moon-location,
    'icon-moon-arrow' $icon-moon-arrow,
    'icon-moon-arrow-down' $icon-moon-arrow-down,
    'icon-moon-arrow-up' $icon-moon-arrow-up,
    'icon-moon-facebook' $icon-moon-facebook,
    'icon-moon-filters' $icon-moon-filters,
    'icon-moon-free' $icon-moon-free,
    'icon-moon-help' $icon-moon-help,
    'icon-moon-listen' $icon-moon-listen,
    'icon-moon-my-account' $icon-moon-my-account,
    'icon-moon-nav-bars' $icon-moon-nav-bars,
    'icon-moon-subscribe' $icon-moon-subscribe,
    'icon-moon-sync' $icon-moon-sync,
    'icon-moon-twitter' $icon-moon-twitter,
    'icon-moon-watch' $icon-moon-watch
;

//@TODO: We may extract this out with icon moon classes if we need to separate printed styles.
@font-face {
    font-family: '#{$font-family-icons}';
    font-style: normal;
    font-weight: normal;
    // @TODO: This should be font-url() but due to compass version, we need to use image-url().
    src: image-url('fonts/icon-moon/icon-moon.woff', false, $icon-moon-cache) format('woff'),
         image-url('fonts/icon-moon/icon-moon.ttf', false, $icon-moon-cache) format('truetype');
}

@mixin icon-moon {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-family-icons;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    vertical-align: middle;

    &:hover:before {
        text-decoration: none;
    }
}

[class*='icon-moon'] {
    @include icon-moon;
}

// Icon-moon Classes.
@each $item in $icon-moon-list {
    .#{nth($item, 1)} {
        &:before {
            content: '#{nth($item, 2)}';
        }
    }
}