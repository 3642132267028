/* Reset. */

* {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    &::after,
    &::before {
        box-sizing: inherit;
    }
}
html {
    box-sizing: border-box;
    max-width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    width: 100vw;
}
body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    max-width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100vw;
}
article,
aside,
footer,
header,
nav,
section,
iframe,
figcaption,
figure,
main {
    display: block;
}
h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
}
li, a {
    &:active,
    &:hover,
    &:visited {
        outline: none;
        text-decoration: none;
    }
    &:focus {
        outline: none;
    }
}
img {
    -ms-interpolation-mode: bicubic;
    border: 0;
}
input,
select,
textarea {
    @include appearance(none);
    border-radius: 0;
    font-family: inherit;
    font-size: 100%;
    vertical-align: baseline;
}
button,
input {
    line-height: normal;
    margin: 0;
    overflow: visible;
}
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
}
textarea {
    overflow: auto;
    vertical-align: top;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
sub,
sup {
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
[hidden] {
    display: none;
}