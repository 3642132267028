/* Animations. */

$transition-speed: 0.2;
$transition-speed2: 0.5;
$transition-speed3: 1;
$transition-speed4: 1.5;

/* Loading ellipsis. */
.loading-ellipsis {
    display: inline-block;
    position: relative;
    width: $spacing-base10 * 5.5;
}
.lds-ellipsis {
    div {
        background-color: rgba($color-white, 0.3);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
        border-radius: 50%;
        height: $spacing-base10;
        top: $spacing-base5;
        position: absolute;
        width: $spacing-base10;
        &:nth-child(1) {
            left: 6px;
            animation: lds-ellipsis1 $transition-speed2 + 0s infinite;
        }
        &:nth-child(2) {
            animation: lds-ellipsis2 $transition-speed2 + 0s infinite;
            left: 6px;
        }
        &:nth-child(3) {
            animation: lds-ellipsis2 $transition-speed2 + 0s infinite;
            left: 26px;
        }
        &:nth-child(4) {
            animation: lds-ellipsis3 $transition-speed2 + 0s infinite;
            left: 45px;
        }
    }
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}

@-webkit-keyframes blinker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes blinker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@mixin animation($animation, $duration, $timing: ease, $delay: 0s, $count: 1, $direction: normal) {
    -webkit-animation: $animation $duration $timing $delay $count $direction;
    -moz-animation: $animation $duration $timing $delay $count $direction;
    -o-animation: $animation $duration $timing $delay $count $direction;
    animation: $animation $duration $timing $delay $count $direction;
}