/* Breakpoints. */

/* 01. Mobile. */
$bp-mobile-min: 321px;
$bp-mobile-max: 375px;
$bp-mobile-plus-max: 414px; // iPhone Plus resolution.

/* 02. Tablet Portrait. */
$bp-tablet-min: 550px; // Awkward breakpoint.
$bp-tablet-small: 700px;
$bp-tablet: 768px;


/* 03. Desktop narrow. */
$bp-desktop-narrow: 1025px;
$bp-desktop-narrow-gutter: 20px;

/* 04. Desktop med. */
$bp-desktop-med: 1280px;

/* 05. Desktop med. */
$bp-desktop-med-max: 1440px;

/* 06. Desktop wide. */
$bp-desktop-wide: 1600px;
$bp-desktop-wide-gutter: 25px;

/* 07. Desktop extra wide. */
$bp-desktop-xwide: 1920px;
$bp-desktop-xwide-gutter: 30px;

/* 08. Mixins. */
@mixin breakpoint($point) {
    @media (min-width: $point) {
        @content;
    }
}

@mixin breakpoint-vertical($point) {
    @media (min-height: $point) {
        @content;
    }
}