/* General Mixins. */

@mixin clearfix {
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin hide-text {
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
}

@mixin appearance($value: none) {
    -webkit-appearance: $value;
    -moz-appearance: $value;
    appearance: $value;
}

// Z-index management. Options are: `below`, `default`, `middle`, `top`, `modal`, and `above-ad`.
@mixin z-layer($layer: default) {
    @if $layer == below {
        z-index: -1;
    }
    @else if $layer == default {
        z-index: 1;
    }
    @else if $layer == middle {
        z-index: 10;
    }
    @else if $layer == top {
        z-index: 20;
    }
    @else if $layer == nav {
        z-index: 500; // Avoid using `nav` unless it is the main nav header.
    }
    @else if $layer == modal {
        z-index: 9999;
    }
    @else if $layer == above-ad {
        z-index: 1000001;
    }
    @else {
        z-index: 1;
    }
}

// Setting percentage widths for columns and removing padding left on new rows.
@mixin col($numOfCol) {
    width: calc(100%/#{$numOfCol});
    &:nth-child(#{$numOfCol}n + 1) {
        margin-left: 0;
        padding-left: 0;
    }
}

@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin triangle($direction: top, $color: red, $size: 1em) {
    // Creates an indicator triangle.
    $perpendicular-borders: $size * 1.5 solid transparent;

    border-#{opposite-direction($direction)}: ($size * 1.5) solid $color;
    content: '';
    height: 0;
    margin-left: -$size;
    position: absolute;
    width: 0;
    z-index: 2;

    @if $direction == top or $direction == bottom {
      border-left:   $perpendicular-borders;
      border-right:  $perpendicular-borders;
    }
    @else if $direction == right or $direction == left {
      border-bottom: $perpendicular-borders;
      border-top:    $perpendicular-borders;
    }
}

@mixin dropdown-style-base {
    @include z-layer(top);
    background-color: $white;
    box-shadow: 0 10px 20px -5px rgba($black, 0.2);
    box-sizing: border-box;
    color: $color-body;
    font-size: 16px;
    padding: $spacing-base24 $spacing-base10 $spacing-base24 $spacing-base32;
    position: absolute;
    text-align: left;
    top: 100%;
}

@mixin load-more-style {
    @include fs-regular-12;
    border-top: 1px solid $light-gray;
    color: $med-blue;
    letter-spacing: 1px;
    line-height: 1;
    margin-top: 20px;
    padding-top: 20px;
    text-align: left;
    text-transform: uppercase;
}

@mixin line-clamp($lines: 3, $line-height: 1.6, $content: "...") {
    // Line Clamp.
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    height: calc(1em * #{$line-height} * #{$lines});
    line-height: $line-height;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-overflow: ellipsis;

    &:after {
        @include background(linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%));
        bottom: 0;
        color: rgba(0, 0, 0, 0.4);
        content: '#{$content}';
        display: block;
        height: calc(1em * #{$line-height});
        position: absolute;
        right: 0;
        text-align: right;
        width: 25%;
    }

    @supports (-webkit-line-clamp: #{$lines}) {
        &:after {
            display: none;
        }
    }
}

/* 
 * @TODO: Can flesh this out to include back up or retina images - or is this still needed?
 * Refer to: http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/.
 */
@mixin background($background-property, $size: false) {
    background: $background-property;
    @if $size {
        background-size: $size;
    }
}

/*
 * Fade a color from top to bottom over an image, so text can go over it.
 * $tint: the opacity of the overlay.
 * $hover: whether to show an animation on hover.
 * $cover: how much of the underlying element to cover.
 * $angle: direction of the overlay.
 * $position: before or after.
 */
@mixin gradient-color-overlay($tint: medium, $hover: false, $cover: half, $angle: -180deg, $position: before) {
    $opacity: null;
    $hover-opacity: null;
    @if $tint == 'none' {
        $opacity: 0;
        $hover-opacity: 0.08;
    }
    @else if $tint == 'x-light' {
        $opacity: 0.1;
        $hover-opacity: 0.2;
    }
    @else if $tint == 'light' {
        $opacity: 0.3;
        $hover-opacity: 0.5;
    }
    @else if $tint == 'medium' {
        $opacity: 0.75;
        $hover-opacity: 0.85;
    }

    $cover-height: $cover;
    @if $cover == 'full' {
        $cover-height: 100%;
    }
    @else if $cover == 'half' {
        $cover-height: 70%;
    }
    @else if $cover == 'quarter' {
        $cover-height: 30%;
    }

    &:#{$position} {
        @include background(linear-gradient($angle, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%));
        bottom: 0;
        content: "";
        display: block;
        height: $cover-height;
        left: 0;
        // Setting opacity instead of changing gradient values because of css transitions.
        opacity: $opacity;
        pointer-events: none;
        position: absolute;
        right: 0;
        @if $hover == true {
            transition: opacity $transition-speed + 0s ease;
        }
    }
    @if $hover == true {
        &:hover {
            &:#{$position} {
                opacity: $hover-opacity;
            }
        }
    }
}

/* 
 * Box Shadow Types.
 * Options are: `standard`, `none`.
 */
@mixin box-shadow($type: standard) {
    @if $type == standard {
        box-shadow: 0 3px 3px 0 rgba(16, 24, 37, 0.25);
    }
    @else if $type == none {
        box-shadow: none;
    }
}

/* 
 * Mixin to keep responsive gutter sizes consistent.
 * Remember to keep this mixin at the bottom of the property list to pick up mobile first styles.
 */
@mixin responsive-gutter($type: full, $showWide: true, $wideflush: false) {
    @if $type == 'full' {
        @include breakpoint($bp-tablet) {
            padding: $gutter-tablet;
            padding-top: $gutter-tablet * 1.5;
        }
        @include breakpoint($bp-desktop-narrow) {
            padding: $gutter-desktop-narrow;
            padding-top: $gutter-desktop-narrow * 1.5;
        }
        @if $showWide == true {
            @include breakpoint($bp-desktop-wide) {
                padding: $gutter-desktop;
                padding-top: $gutter-desktop * 1.5;
            }
        }
    }
    @else if $type == 'sides' {
        @include breakpoint($bp-tablet) {
            padding-left: $gutter-tablet;
            padding-right: $gutter-tablet;
        }
        @include breakpoint($bp-desktop-narrow) {
            padding-left: $gutter-desktop-narrow;
            padding-right: $gutter-desktop-narrow;
        }
        @if $showWide == true {
            @include breakpoint($bp-desktop-wide) {
                padding-left: $gutter-desktop;
                padding-right: $gutter-desktop;
            }
        }
    }
    @else if $type == 'bottomless' {
        @include breakpoint($bp-tablet) {
            padding: $gutter-tablet * 1.5 $gutter-tablet 0;
        }
        @include breakpoint($bp-desktop-narrow) {
            padding: $gutter-desktop-narrow * 1.5 $gutter-desktop-narrow 0;
        }
        @if $showWide == true {
            @include breakpoint($bp-desktop-wide) {
                padding: $gutter-desktop * 1.5 $gutter-desktop 0;
            }
        }
    }
    @else if $type == 'top' {
        @include breakpoint($bp-tablet) {
            padding-top: $gutter-tablet * 1.5;
        }
        @include breakpoint($bp-desktop-narrow) {
            padding-top: $gutter-desktop-narrow * 1.5;
        }
        @if $showWide == true {
            @include breakpoint($bp-desktop-wide) {
                padding-top: $gutter-desktop * 1.5;
            }
        }
    }

    @if $wideflush == true {
        @include breakpoint($bp-desktop-wide) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}