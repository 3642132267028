/* Custom functions. */

/* Since we are not using Compass - we create own opposite-direction Function.
 * @param {List} $directions - List of initial directions
 * @return {List} - List of opposite directions
 */
@function opposite-direction($directions) {
    $opposite-directions: ();
    $direction-map: (
        'top':    'bottom',
        'right':  'left',
        'bottom': 'top',
        'left':   'right',
        'center': 'center',
        'ltr':    'rtl',
        'rtl':    'ltr'
    );
 
    @each $direction in $directions {
        $direction: to-lower-case($direction);

        @if map-has-key($direction-map, $direction) { 
            $opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
        } @else {
            @warn 'No opposite direction can be found for `#{$direction}`. Direction omitted.';
        }
    }
 
    @return $opposite-directions;
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

/*
 * Select all children if element contains less than n children.
 *
 * @return {List} - A list of selectors.
 */
@function less-than($n){
    $selector-base: '& > *:nth-last-child(-n+#{$n - 1}):first-child';
    @return '#{$selector-base}',
            '#{$selector-base} ~ *';
}

/*
 * Select remaining children of a modulo operation.
 *
 * @param {Number} $mod - The modulo divisor.
 * @param {Bool} $keep-first [true] - Keep the first set of children,
 * if quotient is 0.
 * Plus 1 added to $mod to include the last row.
 * @return {List} - A list of selectors.
 */
@function modulo($mod, $keep-first: true) {
    $selector: join((),(),comma);

    @for $i from 2 through ($mod + 1) {
        $selector: append($selector, '& > *:nth-child(#{$mod}n):nth-last-child(#{$i}) ~ *');
    }

    @if not $keep-first {
        $selector: append($selector, less-than($mod));
    }

    @return $selector;
}

/*
 * Counting items to check if items are only 1 row long, if so apply styles to that singular row.
 * This is used for items that may or may not have multiple rows.
 *
 * @param {Number} $number - Number of items per row.
 * @var {Number} $startingNumber - '1' is used to create pattern.
 * @return {List} - A list of selectors.
 */
@function count-selector($number) {
    $selector: join((),(),comma);
    $startingNumber: 1;

    @for $i from 1 through $number {
        $selector: append($selector, '&:nth-child(#{$number}):nth-last-child(#{$startingNumber})');
        $number: $number - 1;
        $startingNumber: $startingNumber + 1;
    }

    @return $selector;
}

