/* Base variables. */

// 50 Shades.
$color-black: #000 !default;
$color-off-black: #232323 !default;
$color-dark-gray: #94A1A9 !default;
$color-med-gray: #999 !default;
$color-light-gray: #E6E7E8 !default;
$color-subdued-gray: #F8F8F8 !default;
$color-white: #FFF !default;

// Primary.
$color-blue: #147CD1 !default;
$color-yellow: #FFBA00 !default;
$color-red: #D0021B !default;
$color-green: #15B842 !default;

// Social Media.
$color-social-fb: #3B5998 !default;
$color-social-twitter: #55ACEE !default;
$color-social-googleplus: #DD4B39 !default;
$color-social-instagram: #517FA4 !default;
$color-social-pinterest: #CB2027 !default;
$color-social-reddit: #FF4500 !default;
$color-social-rss: #FF8B00 !default;

// Other.
$color-dark-blue-gray: #263340;
$color-navy: #1F2933;
$color-navy-black: #13161A;
$color-blue-bright: #2287E6 !default;
$color-orange: #FF6500 !default;
$color-start-bg: #2E3E4D !default;

// Spacing (Multiples of 5 & 8).
$spacing-base5: 5px !default;
$spacing-base8: 8px !default;
$spacing-base10: 10px !default;
$spacing-base16: 16px !default;
$spacing-base24: 24px !default;
$spacing-base32: 32px !default;

// Aspect Ratio.
$video-aspect-ratio: 56.25%;

/*
 * Theme - Default variable values.
 * Values will be overwritten by variables settings in theme/theme.scss.
 */
$color-primary: $color-black !default;
$color-secondary: $color-light-gray !default;
$color-tertiary: $color-dark-blue-gray !default;
$color-link: $color-blue !default;
$color-link-hover: darken($color-blue, 10%) !default;
$color-body: $color-black !default;
$color-body-inverse: $color-white !default;
$color-bg: $color-navy-black !default;
$color-block-bg: $color-white !default;
$color-button-highlight: $color-orange !default;
$color-playlist-bg: $color-tertiary !default;
$color-video-card-bg: rgba($color-black, .2) !default;
$color-video-page-bg: transparent !default;
$color-schedule-page-bg: $color-tertiary !default;

$gutter-mobile: $spacing-base16;
$gutter-tablet: $spacing-base10 * 2;
$gutter-desktop-narrow: $spacing-base24;
$gutter-desktop: $spacing-base32;

// Site logo.
$color-main-nav-bg: $color-primary !default;
$color-main-footer-bg: $color-primary !default;
$color-mobile-nav-bg: $color-navy !default;
$site-logo-height: 50px;
$site-logo-width: 150px;
