/* 
 * Themes.
 * Update the below to customize template.
 */
 
 /* Logo. */
$site-logo: 'logos/logo-sportslive.svg';
$site-logo-height: 40px;
$site-logo-width: 240px;

/* Colors. */
$color-primary: $color-off-black;
$color-tertiary: $color-tertiary;
$color-bg: $color-navy-black;
$color-body: $color-off-black;
$color-body-inverse: $color-white;
$color-main-nav-bg: $color-dark-blue-gray;
$color-main-footer-bg: $color-dark-blue-gray;
$color-link: $color-blue;
$color-link-hover: darken($color-blue, 10%);

/* Fonts. */
$font-family-base: 'proxima-nova', Arial, Helvetica, sans-serif;
$font-family-cond: 'proxima-nova-condensed', 'proxima-nova', Arial, Helvetica, sans-serif;

