/* Typography. */

/* Variables. */
$font-size-base: 10px;
$font-family-base: 'proxima-nova', Arial, Helvetica, sans-serif;
$font-family-cond: 'proxima-nova-condensed', 'proxima-nova', Arial, Helvetica, sans-serif;

$weight-light: 300;
$weight-regular: 400;
$weight-semibold: 600;
$weight-bold: 700;
$weight-xbold: 800;

/* Mixins. */
$font-family-url: '//sports.cbsimg.net/fonts/proxima-nova/';

@mixin declare-font-face($font-family, $font-filename, $font-weight: normal, $font-style: normal) {
    @font-face {
        font-family: '#{$font-family}';
        font-style: $font-style;
        font-weight: $font-weight;
        src: url('#{$font-family-url}' + '#{$font-filename}' + '/webfont.woff2') format('woff2'),
             url('#{$font-family-url}' + '#{$font-filename}' + '/webfont.woff') format('woff'),
             url('#{$font-family-url}' + '#{$font-filename}' + '/webfont.ttf') format('truetype');
    }
}

/* Font declaration. */
@include declare-font-face('proxima-nova', 'extra-bold', $weight-xbold);
@include declare-font-face('proxima-nova', 'bold', $weight-bold);
@include declare-font-face('proxima-nova', 'semi-bold', $weight-semibold);
@include declare-font-face('proxima-nova', 'regular', $weight-regular);
@include declare-font-face('proxima-nova', 'light', $weight-light);
@include declare-font-face('proxima-nova-condensed', 'semi-bold-condensed');