/* Base import. */

@import '../../base';

.error-page {
    &--404 {
        .error-page__container {
            @include background(transparent image-url($error-404-bg) no-repeat top center, cover);
        }
    }
    &--500 {
        .error-page__title {
            font-size: 100px;
        }
    }
    &__container {
        height: 100vh;
        width: 100vw;
    }
    &__message {
        background-color: rgba(0, 0, 0, 0.4);
        color: $color-body-inverse;
        padding: $spacing-base32;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__site-logo {
        left: 50%;
        margin-bottom: $spacing-base16;
        max-width: 50%;
        transform: translate(50%);
        width: 50%;
    }
    &__title {
        font-size: 50px;
        font-weight: $weight-xbold;
        margin-bottom: $spacing-base16;
    }
    &__link-back {
        @include box-shadow();
        background-color: rgba($color-blue, 0.9);
        border-radius: 2px;
        color: $color-body-inverse;
        cursor: pointer;
        display: inline-block;
        font-size: 1.8rem;
        font-weight: $weight-semibold;
        letter-spacing: 0.5px;
        line-height: 1.4;
        padding: $spacing-base5 $spacing-base10;
        text-transform: uppercase;
        &:hover {
            color: $color-body-inverse;
        }
    }
    &__text {
        color: $color-body-inverse;
        display: inline-block;
        font-size: 1.8rem;
        font-weight: $weight-semibold;
    }
}